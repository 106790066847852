
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$client-primary: mat.define-palette(mat.$indigo-palette);
$client-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$client-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$client-theme: mat.define-light-theme((
  color: (
    primary: $client-primary,
    accent: $client-accent,
    warn: $client-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($client-theme);

@import "~@ng-select/ng-select/themes/default.theme.css";

//Gallery
@import '~lightgallery/scss/lightgallery';

/* You can add global styles to this file, and also import other style files */

html, body, app-root {
    height: 100%;
  }


  html {
    height: 100%;
    margin: 0;
}
body {
    width: 100%;
    height: 100vh;
    background-color: #f4f4f4;
    padding: 0;
    margin: 0;
}
body.body-login {
    background-color: #fff;
    padding: 2%;
}
a{
    text-decoration: none !important;
}

.logo {
    width: 100%;
    text-align: center;
}
.container-general {
    padding-top: 75px;
}
.container-menu {
    background-color: #fff;
    border-right: 1px solid #e95f47;
    color: #575656;
    overflow-y: auto;
}
.container-main {
    grid-area: main;
}
:root,
.scroll-container {
    scrollbar-color: #e95f47 #fff;
    scrollbar-width: thin;
}
.main-menu li a.active,
.main-menu li a:hover,
.centerDetails div a:hover .card {
    background-color: #fff3f0;
}
.main-menu a {
    color: #575656;
}
.menu-icon {
    width: 32px;
}
.menu-icon-2 {
    width: 56px;
}

.fs-7 {
    font-size: .875rem;
}

.btn-xs {
    font-size: .75rem;
    padding: .2rem .5rem;
}

.sub-menu {
    width: 200px;
    background: #fff;
    z-index: 2;
    position: fixed;
    top: 75px;
    left: 150px;
    border-left: 1px solid #f3f3f3;
    border-radius: 0 15px 15px 0;
    -webkit-box-shadow: 0 3px 30px rgba(0,0,0,.1), 0 3px 20px rgba(0,0,0,.1);
    box-shadow: 0 3px 30px rgba(0,0,0,.1), 0 3px 20px rgba(0,0,0,.1);
    height: calc(100vh - 75px);
    padding: 10px 10px 10px 20px;
    overflow-y: auto;
}
.sub-menu a {
    font-size: .8rem;
}
.sub-menu .active,
.sub-menu a.active {
    color: var(--bs-link-hover-color);
}
.sub-menu ul.third-level-menu li:hover:after,
.sub-menu ul.third-level-menu li.active:after {
    content: " ";
    background: #e95f47;
    border-radius: 10px;
    position: absolute;
    width: 4px;
    height: 4px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: -16px;
}

.btn-third-menu:hover,
.btn-third-menu.active {
    background-color: #fff3f0;
    color: #575656;
    border-color: #fff3f0 !important;
}
.btn-third-menu:focus {
    border-color: #fff3f0;
}

@media (min-width: 992px){
    .container-general {
        display: grid;
        grid-template-areas: "sidebar main";
        grid-template-columns: 150px calc(100vw - 200px);
        gap: 1.5rem;
    }
    .container-general-withsubmenu {
        grid-template-columns: 350px calc(100vw - 400px);
    }
    .container-menu {
        width: 150px;
        position: fixed; /*sticky;*/
        top: 75px;
        height: calc(100vh - 75px);
        overflow-y: auto;
        display: block;
    }
    .container-main {
        grid-template-areas:
            "intro toc"
            "content toc";
        grid-template-columns: calc(100vw - 200px);
    }
    .sub-menu {
        display: block;
    }
    .container-main-withsubmenu {
        grid-template-columns: calc(100vw - 400px);
    }
}
@media (min-width: 768px) {
    .container-main {
        display: grid;
        grid-template-areas:
            "intro"
            "toc"
            "content";
        gap: inherit;
    }

    .border-start-md-1 {
        border-left: 1px solid !important;
    }
}
@media (max-width: 991.98px) {
    .offcanvas-lg.offcanvas-start {
      border-right-color: #e95f47;
    }
    .offcanvas-lg.show:not(.hiding){
      overflow-y: auto;
    }
    .container-main {
        width: 100vw;
        padding: 2vw;
    }
    .sub-menu {
        display: none;
    }
    /* .container-main-withsubmenu {
        width: calc(100vw - 230px);
    } */
}
@media (max-width: 360px) {
    .content {
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
    }
    .content-login {
        width: 80%;
        margin-top: 10vh;
    }
}

@media (max-height: 800px) {
    .content-login {
        margin-top: 10vh;
    }
}

a{
    color: #333;
}
a:focus, a:hover,
.centerDetails div a:hover .card {
    color: #e95f47;
}

.form-control::placeholder { /* Most modern browsers support this now. */
    color: #AAA;
}
 ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #AAA;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #AAA;
    opacity: 1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #AAA;
    opacity: 1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #AAA;
}
::-ms-input-placeholder { /* Microsoft Edge */
    color: #AAA;
}

.mat-dialog-container {
    padding: 0 !important;
}

.card-header-modal{
    padding: 1rem 1rem;
    color: #575656;
    border-bottom: 1px solid #dee2e6;
}

.card-header-modal .btn-close{
    color: #575656 !important;
}

.card-footer{
    border-top: 1px solid #dee2e6;
}


.card-footer-actions>* {
    margin: calc(0.5rem * .5);
}


.invalid-feedback:not([hidden]) {
    display: block!important
}

.cdk-overlay-container{
    z-index: 99998 !important;
}
.lg-container, .lg-outer, .lg-backdrop{
    z-index: 99999 !important;
}

.bg-pic-green {
    background-color: #97bf0d;
}
.bg-pic-blue {
    background-color: #0062a9;
}
.bg-pic-purple {
    background-color: #804180;
}
.bg-pic-gray {
    background-color: #adb5bd;
}
.bg-pic-cyan{
    background-color: #6edff6;
}
.bg-pic-teal{
    background-color: #20c997;
}
.bg-pic-red{
    background-color: #dc3545;
}
.bg-pic-yellow{
    background-color: #ffc107;
}
.bg-pic-brown{
    background-color: #653208;
}
.bg-pic-red-dark{
    background-color: #842029;
}
.bg-pic-gray-dark{
    background-color: #212529;
}
.bg-pic-brown-light{
    background-color: #994b0b;
}
.bg-pic-cyan-dark{
    background-color: #34c8bf;
}
.bg-pic-gray-dark-2{
    background-color: #7f868d;
}
.bg-pic-gray-orange{
    background-color: #ff6a07;
}
.bg-pic-gray-white{
    background-color: #ffffff;
    color: black;
}
.bg-pic-purple-blue {
    background-color: #7b68ee;
}

.text-pic-grey-dark,
.text-pic-grey-dark a {
    color: #575656;
}

.jstree-open > .jstree-anchor > .fa-folder:before {
    margin-left: 2px;
    content: "\f07c";
}

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";


.mat-dialog-content {
    margin: 0 !important;
    padding: 0 !important;
    max-height: 100vh !important;
}

.ng-select.custom {
    padding: 0;
}
.ng-select.custom .ng-select-container  {
    color: #333;
    background-color: #efefef;
    border-radius: 0;
    border-color: #efefef;
    padding: .375rem .6em;
    font-size: 1rem;
}
.ng-select.ng-select-opened.custom .ng-select-container {
    background-color: #efefef;
    box-shadow: none;
    border-color: #e95f47;
}
.ng-select.custom .ng-select-focused {
    background-color: #efefef;
    box-shadow: none;
    border-color: #e95f47;
}
.ng-select.custom .ng-value-container {
    padding-left: 0;
}
.custom .ng-dropdown-panel {
    background-color: #efefef;
    border-radius: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-color: #e95f47;
}
.custom .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    background-color: #efefef;
}
.custom .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.custom .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked,
.custom .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    color: #fff;
    background-color: #e95f47;
}
.ng-select.custom.ng-select-multiple .ng-select-container  {
    padding-top: 0;
    padding-bottom: 0;
}
.ng-select.custom.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    background-color: #efefef;
}
.ng-select.custom.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
    border-right: none;
}
.ng-select.custom.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
    color: #efefef;
    background-color: #e95f47;
}
.ng-select.custom.ng-select-disabled, 
.ng-select.custom.ng-select-disabled .ng-select-container, 
.ng-select.custom.ng-select-disabled .ng-select-container .ng-value-container,
.ng-select.custom.ng-select-disabled .ng-select-container .ng-value-container .ng-value,
.ng-select.custom.ng-select-disabled .ng-select-container .ng-value-container .ng-input input{
    cursor: not-allowed;
}
.ng-select.custom.ng-select-disabled .ng-select-container .ng-value-container{
    color: rgb(87, 86, 86, 0.75);
}
.box-overflow-visible .mat-dialog-content,
.box-overflow-visible .mat-dialog-container {
    overflow: visible !important;
}

.custom-pagination .mat-paginator {
    color: #575656 !important;
    font-size: 0.875rem !important;
    background-color: transparent !important;
}
.custom-pagination .mat-icon-button {
    color: #575656 !important;
}
.custom-pagination .mat-paginator-icon {
    border: 1px solid #575656;
    border-radius: 3px;
}
.custom-pagination .mat-icon-button.mat-button-disabled {
    color: rgb(87, 86, 86, 0.5) !important;
}
.custom-pagination .mat-icon-button.mat-button-disabled .mat-paginator-icon {
    border: 1px solid rgb(87, 86, 86, 0.5);
    border-radius: 3px;
}
.custom-pagination .mat-paginator-container{
    justify-content: space-between !important;
    padding: 0 !important;
}
.custom-pagination .mat-paginator-page-size {
    margin-right: 0 !important;
}
.custom-pagination .mat-paginator-page-size-label{
    display: none;
}
.custom-pagination .mat-paginator-range-label{
    margin: 0 !important;
}
.custom-pagination .mat-paginator-page-size-select{
    margin: 0 !important;
}
.custom-pagination .mat-paginator-page-size-select .mat-form-field-flex {
    margin-top: 15px;
}
.custom-pagination .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 0.4375em 0;
    border: 1px solid #e95f47;
    background-color: #fff;
    border-radius: 3px;
}
.custom-pagination .mat-form-field-appearance-legacy .mat-form-field-underline {
    height: 0;
}
.custom-pagination .mat-select-value {
    color: #e95f47;
    font-weight: bold;
    padding-left: 3px;
}

.card-header {
    background-color: #ECECEC;
}

/** Tema personalizado para el Datepicker */
.theme-custom .bs-datepicker-head{
    background-color: #e95f47;
}
.theme-custom .bs-datepicker-body table td.week span{
    color: #e95f47;
}
.theme-custom .bs-datepicker-body table td span.selected, .theme-custom .bs-datepicker-body table td.selected span, .theme-custom .bs-datepicker-body table td span[class*=select-]:after, .theme-custom .bs-datepicker-body table td[class*=select-] span:after{
    background-color: #e95f47;
}
/** Final tema personalizado para el Datepicker */

.line-break {
    white-space: pre-line;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%
}

.custom-file {
    display: inline-block;
    margin-bottom: 0
}

.custom-file-label {
    left: 0;
    z-index: 1;
    font-weight: 400;
    background-color: #efefef;
    border-radius: 0;
    color: #AAA;
    font-size: 1rem;
    border-color: #d7d7d7;
    position: absolute;
    top: 0;
    right: 0;
    padding: .375rem .75rem;
    line-height: 1.5;
}

.input-group>.custom-file {
    display: flex;
    align-items: center
}

.input-group>.custom-file,.input-group>.custom-select,.input-group>.form-control,.input-group>.form-control-plaintext {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0
}

.custom-file,.custom-file-input {
    position: relative;
    width: 100%;
    height: calc(1.5em + .75rem + 2px)
}

.custom-file-input,.custom-file-label {
    box-shadow: none!important
}

.custom-select:focus {
    border-color: #e95f47
}

.custom-file-input {
    z-index: 2;
    margin: 0;
    opacity: 0
}

.custom-file,.custom-file-input {
    position: relative;
    width: 100%;
    height: calc(1.5em + .75rem + 2px)
}

.input-group>.custom-file:not(:last-child) .custom-file-label,.input-group>.custom-file:not(:last-child) .custom-file-label:after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.custom-control-label:before,.custom-file-label,.custom-select {
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

.custom-file-label.file-selected{
    color: #333333
}

.card.outline-primary {
    border: 1px solid #e95f47;
}

.autocomplete-container, .autocomplete-container .input-container input {
    box-shadow: none !important;
    height: 38px !important;
}

.suggestions-container {
    background-color: #efefef !important;
    border-radius: 0 !important;
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
    position: absolute !important;
    width: 100% !important;
    z-index: 9999 !important;
}

.suggestions-container ul li{
    border-left: 1px solid #e95f47 !important;
    border-right: 1px solid #e95f47 !important;
}

.suggestions-container ul li:first-child{
    border-top: 1px solid #e95f47 !important;
}

.suggestions-container ul li:last-child{
    border-bottom: 1px solid #e95f47 !important;
}

.p-08 {
    padding: 0.8rem !important;
}

mat-dialog-container{
    border-radius: 0.5rem !important;
    border: 1px solid #f1aeae !important;
}

.map-alerts-full {
    height: 500px;
}

.t-5-p{
    width: 5% !important;
}

input[type="text"].form-control.text-box.single-line, ng-autocomplete input[type="text"] {
    text-transform: uppercase !important;
}

.map-alerts-popup {
    height: 200px;
}

.gm-style-iw-d {
    overflow: hidden !important; 
    line-height: 1.35;
    white-space: nowrap;
 }

.app-menu {
    z-index: 1;
    background: #fff;
    transition: transform .3s;
    top: 100px;
}

.scroll {
    position: relative;
    height: 100%;
    max-width: unset;
    width: unset;
    margin-right: -15px;
    padding-right: 15px;
}

.app-menu a.active {
    color: #e95f47;
}