/*
    CSS para modificar estilos de bootstrap
*/
:root{
    --bs-primary:#e95f47;
    --bs-primary-rgb:233,95,71;
    --bs-font-sans-serif: Arial, Helvetica, sans-serif;
    --bs-link-color: #333;
    --bs-link-hover-color: #e95f47;
    --bs-body-color: #575656;
    --bs-body-font-size: .875rem;
}

.btn-primary {
    --bs-btn-bg: #e95f47;
    --bs-btn-border-color: #e95f47;
    --bs-btn-hover-bg: #f08e74;
    --bs-btn-hover-border-color: #f08e74;
    --bs-btn-active-bg: #f08e74;
    --bs-btn-active-border-color: #f08e74;
    --bs-btn-disabled-bg: #e95f47;
    --bs-btn-disabled-border-color: #e95f47;
}

.btn-outline-primary {
    --bs-btn-color: #e95f47;
    --bs-btn-border-color: #e95f47;
    --bs-btn-hover-bg: #e95f47;
    --bs-btn-hover-border-color: #e95f47;
    --bs-btn-active-bg: #e95f47;
    --bs-btn-active-border-color: #e95f47;
    --bs-btn-disabled-color: #e95f47;
    --bs-btn-disabled-border-color: #e95f47;
    --bs-btn-bg: #fff;
    --bs-btn-hover-bg: #f08e74;
    --bs-btn-hover-border-color: #f08e74;
}

.card {
    --bs-card-border-color: #f1aeae;
    --bs-card-border-radius: var(--bs-card-border-width);
}

mat-dialog-container .card{
    --bs-card-border-color: transparent;
}

.table {
    --bs-table-color: #58585a;
    --bs-table-bg: #fff;
}
/* .table > :not(caption) > * > * {
    padding: .75rem 1rem;
} */

.pagination {
    --bs-pagination-border-color: #e95f47;
    --bs-pagination-hover-border-color: #f08e74;
    --bs-pagination-hover-bg: #f08e74;
    --bs-pagination-focus-bg: #f08e74;
    --bs-pagination-focus-box-shadow: none;
    --bs-pagination-color: #e95f47;
    --bs-pagination-active-bg: #e95f47;
    --bs-pagination-active-border-color: #e95f47;
    --bs-pagination-font-size: .875rem;
    --bs-pagination-padding-x: 0.5rem;
    --bs-pagination-padding-y: 0.275rem;
}

.form-control,
.form-select,
ng-autocomplete.custom .autocomplete-container .input-container input{
    color: #333333;
    background-color: #efefef;
    border-radius: 0;
    border-color: #efefef;
    padding: .375rem .6em;
}
.form-control:focus,
.form-select:focus,
ng-autocomplete.custom .autocomplete-container .input-container input:focus{
    background-color: #efefef;
    box-shadow: none;
    border-color: #e95f47;
}
.form-control.form-readonly {
    color: rgb(51, 51, 51, 0.75);
    cursor: not-allowed
}
.form-control.form-readonly:focus {
    border-color: #efefef;
}
.form-check-input:checked {
    background-color: #e95f47;
    border-color: #e95f47;
}
.form-check-input:focus {
    border-color: #e95f47;
    outline: 0;
    box-shadow: none;
}
.form-switch .form-check-input {
    height: 1.125em;
}
.btn-close:focus {
    box-shadow: none;
}
